import { useTheme, Typography, Box, Link, Button, Paper } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";

import cn from "classnames";

import tableStyles from "./Table.module.css";

export function BeforeAlerts({
  onSetHighlightedSection,
}: {
  onSetHighlightedSection: (s: any) => void;
}) {
  const theme = useTheme();

  return (
    <>
      {/* Alerts */}
      <Typography variant="h6" component="h3" marginBottom={1}>
        Alert log
      </Typography>
      <Box mb={5}>
        <Paper elevation={2}>
          <div className={cn(tableStyles.tableWrap)}>
            <table className={cn(tableStyles.table)}>
              <colgroup>
                <col span={1} style={{ width: "20%" }} />
                <col span={1} style={{ width: "20%" }} />
                <col span={1} style={{ width: "60%" }} />
              </colgroup>

              <thead className={cn(tableStyles.left)}>
                <tr>
                  <th className={cn(tableStyles.headercol)}>
                    <div className={cn(tableStyles.resizer)} />
                    <Box alignItems="center" display="flex" gap="0.5em">
                      Alert date{" "}
                      <FilterListIcon
                        fontSize="small"
                        style={{
                          marginTop: "-0.125em",
                          color: theme.palette.grey[500],
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  </th>
                  <th>
                    <div className={cn(tableStyles.resizer)} />
                    <Box alignItems="center" display="flex" gap="0.5em">
                      Status{" "}
                      <FilterListIcon
                        fontSize="small"
                        style={{
                          marginTop: "-0.125em",
                          color: theme.palette.grey[500],
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  </th>
                  <th>
                    <Box alignItems="center" display="flex" gap="0.5em">
                      Type{" "}
                      <FilterListIcon
                        fontSize="small"
                        style={{
                          marginTop: "-0.125em",
                          color: theme.palette.grey[500],
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  </th>
                </tr>
              </thead>
              <tbody className={cn(tableStyles.left)}>
                <tr>
                  <td colSpan={3}>
                    <div
                      className={cn(tableStyles.emptyMessage)}
                      style={{ padding: "0.5em" }}
                    >
                      No alerts
                    </div>
                  </td>
                </tr>
                {/* <tr>
                  <td>
                    December 6, 2024
                  </td>
                  <td>
                    <span style={{ color: theme.palette.error.main }}><strong>OPEN</strong></span>
                  </td>
                  <td>
                    <Box display="flex" alignItems="center">
                      <div style={{ flex: 1 }}>
                        <div>
                          <Link
                            style={{ cursor: "pointer" }}
                            color={theme.palette.grey[800]}
                            onClick={() => {
                              onSetHighlightedSection("website_content" as any);
                            }}
                          >
                            Subscriptions
                          </Link>
                        </div>
                        <div style={{ fontSize: "0.85em", color: theme.palette.grey[500] }}>
                          Subscription offerings detected
                        </div>
                      </div>
                      <div>
                        <Button variant="outlined" size="small" style={{ color: theme.palette.grey[600], borderColor: theme.palette.grey[500] }}>
                          Resolve
                        </Button>
                      </div>
                    </Box>
                  </td>
                </tr>
                <tr>
                  <td>
                    December 3, 2024
                  </td>
                  <td>
                    <div>Resolved / Notice send</div>
                    <div style={{ fontSize: "0.85em", color: theme.palette.grey[500] }}>danny@truebiz.io &ndash; 12/4/2024</div>
                  </td>
                  <td>
                    <div>
                      <Link
                        style={{ cursor: "pointer" }}
                        color={theme.palette.grey[800]}
                        onClick={() => {
                          onSetHighlightedSection("website_content" as any);
                        }}
                      >
                        Online auctions
                      </Link>
                    </div>
                    <div style={{ fontSize: "0.85em", color: theme.palette.grey[500] }}>
                      The website might be conducting online auctions
                    </div>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </Paper>
      </Box>
      {/* END Alerts */}
    </>
  );
}

export function AfterAlerts({
  onSetHighlightedSection,
}: {
  onSetHighlightedSection: (s: any) => void;
}) {
  const theme = useTheme();

  return (
    <>
      {/* Alerts */}
      <Typography variant="h6" component="h3" marginBottom={1}>
        Alert log
      </Typography>
      <Box mb={5}>
        <Paper elevation={2}>
          <div className={cn(tableStyles.tableWrap)}>
            <table className={cn(tableStyles.table)}>
              <colgroup>
                <col span={1} style={{ width: "20%" }} />
                <col span={1} style={{ width: "20%" }} />
                <col span={1} style={{ width: "60%" }} />
              </colgroup>

              <thead className={cn(tableStyles.left)}>
                <tr>
                  <th className={cn(tableStyles.headercol)}>
                    <div className={cn(tableStyles.resizer)} />
                    <Box alignItems="center" display="flex" gap="0.5em">
                      Alert date{" "}
                      <FilterListIcon
                        fontSize="small"
                        style={{
                          marginTop: "-0.125em",
                          color: theme.palette.grey[500],
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  </th>
                  <th>
                    <div className={cn(tableStyles.resizer)} />
                    <Box alignItems="center" display="flex" gap="0.5em">
                      Status{" "}
                      <FilterListIcon
                        fontSize="small"
                        style={{
                          marginTop: "-0.125em",
                          color: theme.palette.grey[500],
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  </th>
                  <th>
                    <Box alignItems="center" display="flex" gap="0.5em">
                      Type{" "}
                      <FilterListIcon
                        fontSize="small"
                        style={{
                          marginTop: "-0.125em",
                          color: theme.palette.grey[500],
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  </th>
                </tr>
              </thead>
              <tbody className={cn(tableStyles.left)}>
                <tr>
                  <td>January 2, 2025</td>
                  <td>
                    <span style={{ color: theme.palette.error.main }}>
                      <strong>OPEN</strong>
                    </span>
                  </td>
                  <td>
                    <Box display="flex" alignItems="center">
                      <div style={{ flex: 1 }}>
                        <div>
                          <Link
                            style={{ cursor: "pointer" }}
                            color={theme.palette.grey[800]}
                            onClick={() => {
                              onSetHighlightedSection("news_articles" as any);
                            }}
                          >
                            Adverse media
                          </Link>
                        </div>
                        <div
                          style={{
                            fontSize: "0.85em",
                            color: theme.palette.grey[500],
                          }}
                        >
                          Concerning media about legal concerns detected
                        </div>
                      </div>
                      <div>
                        <Button
                          variant="outlined"
                          size="small"
                          style={{
                            color: theme.palette.grey[600],
                            borderColor: theme.palette.grey[500],
                          }}
                        >
                          Resolve
                        </Button>
                      </div>
                    </Box>
                  </td>
                </tr>
                {/* <tr>
                  <td>
                    December 6, 2024
                  </td>
                  <td>
                    <span style={{ color: theme.palette.error.main }}><strong>OPEN</strong></span>
                  </td>
                  <td>
                    <Box display="flex" alignItems="center">
                      <div style={{ flex: 1 }}>
                        <div>
                          <Link
                            style={{ cursor: "pointer" }}
                            color={theme.palette.grey[800]}
                            onClick={() => {
                              onSetHighlightedSection("website_content" as any);
                            }}
                          >
                            Subscriptions
                          </Link>
                        </div>
                        <div style={{ fontSize: "0.85em", color: theme.palette.grey[500] }}>
                          Subscription offerings detected
                        </div>
                      </div>
                      <div>
                        <Button variant="outlined" size="small" style={{ color: theme.palette.grey[600], borderColor: theme.palette.grey[500] }}>
                          Resolve
                        </Button>
                      </div>
                    </Box>
                  </td>
                </tr>
                <tr>
                  <td>
                    December 3, 2024
                  </td>
                  <td>
                    <div>Resolved / Notice send</div>
                    <div style={{ fontSize: "0.85em", color: theme.palette.grey[500] }}>danny@truebiz.io &ndash; 12/4/2024</div>
                  </td>
                  <td>
                    <div>
                      <Link
                        style={{ cursor: "pointer" }}
                        color={theme.palette.grey[800]}
                        onClick={() => {
                          onSetHighlightedSection("website_content" as any);
                        }}
                      >
                        Online auctions
                      </Link>
                    </div>
                    <div style={{ fontSize: "0.85em", color: theme.palette.grey[500] }}>
                      The website might be conducting online auctions
                    </div>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </Paper>
      </Box>
      {/* END Alerts */}
    </>
  );
}
