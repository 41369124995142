import {
  Box,
  Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
  Link,
} from "@mui/material";

import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { ReactComponent as ContentSparkLineBefore } from "./content-before.svg";
import { ReactComponent as PhysicalSparkLineBefore } from "./physical-before.svg";
import { ReactComponent as SentimentSparkLineBefore } from "./sentiment-before.svg";
import { ReactComponent as WebsiteSparkLineBefore } from "./website-before.svg";

import { ReactComponent as ContentSparkLineAfter } from "./content-after.svg";
import { ReactComponent as PhysicalSparkLineAfter } from "./physical-after.svg";
import { ReactComponent as SentimentSparkLineAfter } from "./sentiment-after.svg";
import { ReactComponent as WebsiteSparkLineAfter } from "./website-after.svg";

export function BeforeFindings({
  onSetHighlightedSection,
}: {
  onSetHighlightedSection: (section: string) => void;
}) {
  const theme = useTheme();

  return (
    <Grid container spacing={2} mb={4} columnSpacing={{ md: 3 }}>
      {/* Sentiment */}
      <Grid item xs={12} md={3}>
        <Paper style={{ minHeight: "425px" }}>
          <Stack alignItems="center" padding={3}>
            <SentimentSparkLineBefore />

            <Typography variant="h5" mt={2} textAlign="center">
              Sentiment
            </Typography>

            <Typography
              variant="h6"
              textTransform="uppercase"
              color={theme.palette.grey[500]}
              mt={4}
            >
              Factors
            </Typography>
            <Stack gap="0.5em" mt={1.5}>
              <Link
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.25em"
                style={{ cursor: "pointer" }}
                color={theme.palette.warning.main}
                onClick={() => {
                  onSetHighlightedSection("reviews" as any);
                }}
              >
                <InfoOutlinedIcon /> Mixed reviews detected
              </Link>

              <Link
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.25em"
                style={{ cursor: "pointer" }}
                color={theme.palette.success.main}
                onClick={() => {
                  onSetHighlightedSection("social_media" as any);
                }}
              >
                <CheckCircleOutlinedIcon /> Social media presence found
              </Link>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
      {/* END Sentiment */}

      {/* Content */}
      <Grid item xs={12} md={3}>
        <Paper style={{ minHeight: "425px" }}>
          <Stack alignItems="center" padding={3}>
            <ContentSparkLineBefore />

            <Typography variant="h5" mt={2} textAlign="center">
              Content
            </Typography>

            <Typography
              variant="h6"
              textTransform="uppercase"
              color={theme.palette.grey[500]}
              mt={4}
            >
              Factors
            </Typography>
            <Stack gap="0.5em" mt={1.5}>
              <Link
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.25em"
                style={{ cursor: "pointer" }}
                color={theme.palette.warning.main}
                onClick={() => {
                  onSetHighlightedSection("website_content" as any);
                }}
              >
                <InfoOutlinedIcon /> Prohibited web content
              </Link>

              <Link
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.25em"
                style={{ cursor: "pointer" }}
                color={theme.palette.warning.main}
                onClick={() => {
                  onSetHighlightedSection("website_policy_analysis" as any);
                }}
              >
                <InfoOutlinedIcon /> Anomalous privacy policy
              </Link>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
      {/* END Content */}

      {/* Website Operations */}
      <Grid item xs={12} md={3}>
        <Paper style={{ minHeight: "425px" }}>
          <Stack alignItems="center" padding={3}>
            <WebsiteSparkLineBefore />

            <Typography variant="h5" mt={2} textAlign="center">
              Website Operations
            </Typography>

            <Typography
              variant="h6"
              textTransform="uppercase"
              color={theme.palette.grey[500]}
              mt={4}
            >
              Factors
            </Typography>
            <Stack gap="0.5em" mt={1.5}>
              <Link
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.25em"
                style={{ cursor: "pointer" }}
                color={theme.palette.success.main}
                onClick={() => {
                  onSetHighlightedSection("contact_info" as any);
                }}
              >
                <CheckCircleOutlinedIcon /> Consistent email and web domain
              </Link>

              <Link
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.25em"
                style={{ cursor: "pointer" }}
                color={theme.palette.success.main}
                onClick={() => {
                  onSetHighlightedSection("webserver_analysis" as any);
                }}
              >
                <CheckCircleOutlinedIcon /> Meaningful technology spend
              </Link>

              <Link
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.25em"
                style={{ cursor: "pointer" }}
                color={theme.palette.success.main}
                onClick={() => {
                  onSetHighlightedSection("website_traffic" as any);
                }}
              >
                <CheckCircleOutlinedIcon /> Website traffic found
              </Link>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
      {/* END Website Operations */}

      {/* Physical Operations */}
      <Grid item xs={12} md={3}>
        <Paper style={{ minHeight: "425px" }}>
          <Stack alignItems="center" padding={3}>
            <PhysicalSparkLineBefore />

            <Typography variant="h5" mt={2} textAlign="center">
              Physical Operations
            </Typography>

            <Typography
              variant="h6"
              textTransform="uppercase"
              color={theme.palette.grey[500]}
              mt={4}
            >
              Factors
            </Typography>
            <Stack gap="0.5em" mt={1.5}>
              <Link
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.25em"
                style={{ cursor: "pointer" }}
                color={theme.palette.warning.main}
                onClick={() => {
                  onSetHighlightedSection("connected_entities" as any);
                }}
              >
                <InfoOutlinedIcon /> Connected entities
              </Link>

              <Link
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.25em"
                style={{ cursor: "pointer" }}
                color={theme.palette.success.main}
                onClick={() => {
                  onSetHighlightedSection("contact_info" as any);
                }}
              >
                <CheckCircleOutlinedIcon /> All phone numbers valid
              </Link>

              <Link
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.25em"
                style={{ cursor: "pointer" }}
                color={theme.palette.success.main}
                onClick={() => {
                  onSetHighlightedSection("industry" as any);
                }}
              >
                <CheckCircleOutlinedIcon /> Industry classified
              </Link>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
      {/* END Physical Operations */}
    </Grid>
  );
}

export function AfterFindings({
  onSetHighlightedSection,
}: {
  onSetHighlightedSection: (section: string) => void;
}) {
  const theme = useTheme();

  return (
    <Grid container spacing={2} mb={4} columnSpacing={{ md: 3 }}>
      {/* Sentiment */}
      <Grid item xs={12} md={3}>
        <Paper style={{ minHeight: "425px" }}>
          <Stack alignItems="center" padding={3}>
            <SentimentSparkLineAfter />

            <Typography variant="h5" mt={2} textAlign="center">
              Sentiment
            </Typography>

            <Typography
              variant="h6"
              textTransform="uppercase"
              color={theme.palette.grey[500]}
              mt={4}
            >
              Factors
            </Typography>
            <Stack gap="0.5em" mt={1.5}>
              <Link
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.25em"
                style={{ cursor: "pointer" }}
                color={theme.palette.error.main}
                onClick={() => {
                  onSetHighlightedSection("news_articles" as any);
                }}
              >
                <CancelOutlinedIcon /> Concerning news articles detected
              </Link>
              <Link
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.25em"
                style={{ cursor: "pointer" }}
                color={theme.palette.warning.main}
                onClick={() => {
                  onSetHighlightedSection("reviews" as any);
                }}
              >
                <InfoOutlinedIcon /> Mixed reviews detected
              </Link>

              <Link
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.25em"
                style={{ cursor: "pointer" }}
                color={theme.palette.success.main}
                onClick={() => {
                  onSetHighlightedSection("social_media" as any);
                }}
              >
                <CheckCircleOutlinedIcon /> Social media presence found
              </Link>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
      {/* END Sentiment */}

      {/* Content */}
      <Grid item xs={12} md={3}>
        <Paper style={{ minHeight: "425px" }}>
          <Stack alignItems="center" padding={3}>
            <ContentSparkLineAfter />

            <Typography variant="h5" mt={2} textAlign="center">
              Content
            </Typography>

            <Typography
              variant="h6"
              textTransform="uppercase"
              color={theme.palette.grey[500]}
              mt={4}
            >
              Factors
            </Typography>
            <Stack gap="0.5em" mt={1.5}>
              <Link
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.25em"
                style={{ cursor: "pointer" }}
                color={theme.palette.warning.main}
                onClick={() => {
                  onSetHighlightedSection("website_content" as any);
                }}
              >
                <InfoOutlinedIcon /> Prohibited web content
              </Link>

              <Link
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.25em"
                style={{ cursor: "pointer" }}
                color={theme.palette.warning.main}
                onClick={() => {
                  onSetHighlightedSection("website_policy_analysis" as any);
                }}
              >
                <InfoOutlinedIcon /> Anomalous privacy policy
              </Link>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
      {/* END Content */}

      {/* Website Operations */}
      <Grid item xs={12} md={3}>
        <Paper style={{ minHeight: "425px" }}>
          <Stack alignItems="center" padding={3}>
            <WebsiteSparkLineAfter />

            <Typography variant="h5" mt={2} textAlign="center">
              Website Operations
            </Typography>

            <Typography
              variant="h6"
              textTransform="uppercase"
              color={theme.palette.grey[500]}
              mt={4}
            >
              Factors
            </Typography>
            <Stack gap="0.5em" mt={1.5}>
              <Link
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.25em"
                style={{ cursor: "pointer" }}
                color={theme.palette.success.main}
                onClick={() => {
                  onSetHighlightedSection("contact_info" as any);
                }}
              >
                <CheckCircleOutlinedIcon /> Consistent email and web domain
              </Link>

              <Link
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.25em"
                style={{ cursor: "pointer" }}
                color={theme.palette.success.main}
                onClick={() => {
                  onSetHighlightedSection("webserver_analysis" as any);
                }}
              >
                <CheckCircleOutlinedIcon /> Meaningful technology spend
              </Link>

              <Link
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.25em"
                style={{ cursor: "pointer" }}
                color={theme.palette.success.main}
                onClick={() => {
                  onSetHighlightedSection("website_traffic" as any);
                }}
              >
                <CheckCircleOutlinedIcon /> Website traffic found
              </Link>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
      {/* END Website Operations */}

      {/* Physical Operations */}
      <Grid item xs={12} md={3}>
        <Paper style={{ minHeight: "425px" }}>
          <Stack alignItems="center" padding={3}>
            <PhysicalSparkLineAfter />

            <Typography variant="h5" mt={2} textAlign="center">
              Physical Operations
            </Typography>

            <Typography
              variant="h6"
              textTransform="uppercase"
              color={theme.palette.grey[500]}
              mt={4}
            >
              Factors
            </Typography>
            <Stack gap="0.5em" mt={1.5}>
              <Link
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.25em"
                style={{ cursor: "pointer" }}
                color={theme.palette.warning.main}
                onClick={() => {
                  onSetHighlightedSection("connected_entities" as any);
                }}
              >
                <InfoOutlinedIcon /> Connected entities
              </Link>

              <Link
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.25em"
                style={{ cursor: "pointer" }}
                color={theme.palette.success.main}
                onClick={() => {
                  onSetHighlightedSection("contact_info" as any);
                }}
              >
                <CheckCircleOutlinedIcon /> All phone numbers valid
              </Link>

              <Link
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.25em"
                style={{ cursor: "pointer" }}
                color={theme.palette.success.main}
                onClick={() => {
                  onSetHighlightedSection("industry" as any);
                }}
              >
                <CheckCircleOutlinedIcon /> Industry classified
              </Link>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
      {/* END Physical Operations */}
    </Grid>
  );
}
