import { useTheme, Container, Link } from "@mui/material";
import { HTMLProps } from "react";
import { Link as RouterLink } from "react-router-dom";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

import NoticeBanner from "./NoticeBanner";
import Logo from "../Logo";
import WebOnly from "../WebOnly";
import HamburgerMenu from "../HamburgerMenu";
import { useIdentity } from "../IdentityProvider";

export interface Props extends Exclude<HTMLProps<HTMLDivElement>, "children"> {
  logo?: boolean;
  navLinks?: boolean;
  hamburgerMenu?: boolean;
  divider?: boolean;
  invert?: boolean;
}
export default function PageHeader({
  logo = true,
  navLinks = true,
  hamburgerMenu = true,
  divider = true,
  invert = false,
  style,
  ...etc
}: Props) {
  const theme = useTheme();
  const hamburgerOnly =
    hamburgerMenu === true && !navLinks && !logo && !divider;

  const identity = useIdentity();

  return (
    <div style={{ width: "100%", ...(style || {}) }}>
      {["Peach Payments", "Fung Payments - Test Account"].includes(
        identity.user?.accountName || ""
      ) ? (
        <NoticeBanner message="Free pilot period ending soon." />
      ) : null}
      <div
        style={{
          background: invert ? theme.palette.primary.main : "unset",
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingTop: "0.25em",
          paddingBottom: invert ? "0.75em" : "0.5em",
          marginBottom: "2em",
          flex: 0,
          borderBottom: divider ? "1px solid #eee" : undefined,
          // ...(style || {}),
        }}
        {...etc}
      >
        <Container
          maxWidth="xl"
          style={{ position: "relative", paddingBottom: "0.25em" }}
        >
          <div
            style={{
              display: "flex",
              marginTop: "0.75em",
              gap: "1.75em",
            }}
          >
            {logo && (
              <RouterLink to="/">
                <Logo
                  height="1.5em"
                  style={{ marginTop: "0.5em" }}
                  reversed={invert}
                />
              </RouterLink>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                gap: "1em",
              }}
            >
              <WebOnly>
                {navLinks && (
                  <div
                    style={{
                      display: "flex",
                      gap: "1.75em",
                      flexShrink: 0,
                      marginTop: "0.5em",
                      justifyContent: "end",
                      paddingRight: hamburgerMenu ? "3em" : undefined,
                    }}
                  >
                    <Link
                      component={RouterLink}
                      to="/monitoring"
                      sx={{
                        display: "flex",
                        gap: "0.25em",
                        color: invert ? "white" : "#8E8E8E",
                        textDecoration: "none",
                        transform: "translateY(4px)",
                      }}
                    >
                      <MonitorHeartIcon
                        style={{ color: invert ? "white" : "#D3D3D3" }}
                      />
                      <span>Monitoring</span>
                    </Link>
                    <Link
                      component={RouterLink}
                      to="/searches"
                      sx={{
                        display: "flex",
                        gap: "0.25em",
                        color: invert ? "white" : "#8E8E8E",
                        textDecoration: "none",
                        transform: "translateY(4px)",
                      }}
                    >
                      <WatchLaterIcon
                        style={{ color: invert ? "white" : "#D3D3D3" }}
                      />
                      <span>Search History</span>
                    </Link>
                  </div>
                )}
              </WebOnly>
            </div>
          </div>

          <WebOnly>
            {hamburgerMenu && !hamburgerOnly && (
              <div
                style={{ position: "absolute", top: "0.5em", right: "0.5em" }}
              >
                <HamburgerMenu tabIndex={0} invert={invert} />
              </div>
            )}
          </WebOnly>
        </Container>

        <WebOnly>
          {hamburgerMenu && hamburgerOnly && (
            <div style={{ position: "absolute", top: "0.5em", right: "1em" }}>
              <HamburgerMenu tabIndex={0} invert={invert} />
            </div>
          )}
        </WebOnly>
      </div>
    </div>
  );
}
