import { createColumnHelper } from "@tanstack/react-table";
import { Chip, Checkbox } from "@mui/material";
import { capitalize, sortBy, uniq } from "lodash";
import { parseISO, format } from "date-fns";

import {
  TableMonitorType,
  type SelectionAction,
  type TableColumn,
  type TableRow,
} from "./types";
import RaggedTextSkeleton from "./RaggedTextSkeleton";
import SelectColumn, {
  ColumnHeader as SelectColumnHeader,
} from "./SelectColumn";

import styles from "./Table.module.css";

export const DEFAULT_COLUMNS: TableColumn[] = [
  "domain",
  "monitor_types",
  "status",
  "last_alerted",
  "next_run",
  "alerts",
  "assigned_to",
];

export const DEFAULT_SELECTION_ACTIONS: SelectionAction[] = [
  "assign",
  "export",
  "pause",
  "resume",
];

export const columnHelper = createColumnHelper<TableRow>();

export const columnDefinitions: Record<
  TableColumn | "_select",
  { whenLoading: any; otherwise: any }
> = {
  _select: {
    whenLoading: {
      id: "select",
      header: () => <SelectColumnHeader disabled />,
      cell: () => <Checkbox disabled />,
    },
    otherwise: new SelectColumn<TableRow>(),
  },
  alerts: {
    whenLoading: {
      id: "alerts",
      header: "Alerts",
      cell: () => <RaggedTextSkeleton />,
      meta: {
        resizable: true,
        relativeSize: 2,
      },
    },
    otherwise: columnHelper.accessor("alerts", {
      id: "alerts",
      meta: {
        filterVariant: "valuePicker",
        sortVariant: "simple",
        resizable: true,
        relativeSize: 2,
      },
      cell: ({ cell }) => (
        <>
          {sortBy(uniq(cell.getValue()))
            .map(capitalize)
            .map((alertType) => (
              <Chip
                sx={{
                  borderRadius: "5px",
                  marginRight: "0.25em",
                  marginTop: "0.25em",
                  marginBottom: "0.25em",
                }}
                key={alertType}
                size="small"
                variant="outlined"
                label={alertType}
                className={styles.InlineChip}
              />
            ))}
        </>
      ),
    }),
  },
  assigned_to: {
    whenLoading: {
      id: "assigned_to",
      header: "Assigned To",
      cell: () => <RaggedTextSkeleton />,
      meta: {
        resizable: true,
      },
    },
    otherwise: columnHelper.accessor("assigned_to", {
      id: "assigned_to",
      header: "Assigned To",
      meta: {
        filterVariant: "valuePicker",
        filterStickyValues: ["unassigned"],
        sortVariant: "simple",
        resizable: true,
      },
      cell: ({ cell, row }) => {
        const value = cell.getValue();
        const assignees = value?.map(
          (assignment) => assignment.assigned_to?.email
        );

        if (assignees?.length) {
          return assignees.join(", ");
        }

        return null;
      },
    }),
  },
  last_alerted: {
    whenLoading: {
      id: "last_alerted",
      header: "Last Alert",
      cell: () => <RaggedTextSkeleton />,
      meta: {
        resizable: true,
      },
    },
    otherwise: columnHelper.accessor("last_alerted", {
      id: "last_alerted",
      header: "Last Alert",
      cell: ({ cell }) => {
        const timestamp = cell.getValue();
        if (!timestamp) return "Never";

        try {
          const parsed = parseISO(timestamp);
          return format(parsed, "MMM d, yyyy");
        } catch (e) {
          return timestamp;
        }
      },
      meta: {
        filterVariant: "dateThreshold",
        sortVariant: "simple",
        resizable: true,
      },
    }),
  },
  monitor_types: {
    whenLoading: {
      id: "monitor_types",
      header: "Monitored For",
      cell: () => <RaggedTextSkeleton />,
      meta: {
        resizable: true,
        relativeSize: 2.5,
      },
    },
    otherwise: columnHelper.accessor("monitor_types", {
      id: "monitor_types",
      header: "Monitored For",
      meta: {
        filterVariant: "valuePicker",
        sortVariant: "simple",
        resizable: true,
        relativeSize: 2.5,
      },
      cell: ({ cell, row }) => {
        // TODO(drew): DELETE ME POST TKHOME DEMO
        const domain = row.getValue("domain");

        return (
          <>
            {sortBy(uniq(cell.getValue()))
              .map((monitorType) => {
                // TODO(drew): DELETE ME POST TKHOME DEMO
                if (
                  monitorType === TableMonitorType.CONNECTED_ENTITIES &&
                  domain === "tkhome.ca"
                ) {
                  return "Adverse media";
                }
                return monitorType;
              })
              .map(capitalize)
              .map((monitorType) => (
                <Chip
                  sx={{
                    borderRadius: "5px",
                    marginRight: "0.25em",
                    marginTop: "0.25em",
                    marginBottom: "0.25em",
                  }}
                  key={monitorType}
                  size="small"
                  variant="outlined"
                  label={monitorType}
                  className={styles.InlineChip}
                />
              ))}
          </>
        );
      },
    }),
  },
  next_run: {
    whenLoading: {
      id: "next_run",
      header: "Next Run",
      cell: () => <RaggedTextSkeleton />,
      meta: {
        resizable: true,
      },
    },
    otherwise: columnHelper.accessor("next_run", {
      id: "next_run",
      header: "Next Run",
      cell: ({ cell }) => {
        const timestamp = cell.getValue();
        if (!timestamp) return "Not scheduled";

        try {
          const parsed = parseISO(timestamp);
          return format(parsed, "MMM d, yyyy");
        } catch (e) {
          return timestamp;
        }
      },
      meta: {
        filterVariant: "dateThreshold",
        sortVariant: "simple",
        resizable: true,
      },
    }),
  },
  domain: {
    whenLoading: {
      id: "domain",
      header: "Domain",
      cell: () => <RaggedTextSkeleton />,
      meta: {
        resizable: true,
        relativeSize: 1.25,
      },
    },
    otherwise: columnHelper.accessor("domain", {
      id: "domain",
      meta: {
        filterVariant: "valuePicker",
        sortVariant: "simple",
        resizable: true,
        relativeSize: 1.25,
      },
    }),
  },
  status: {
    whenLoading: {
      id: "status",
      header: "Status",
      cell: () => <RaggedTextSkeleton />,
      meta: {
        resizable: true,
      },
    },
    otherwise: columnHelper.accessor("status", {
      id: "status",
      meta: {
        filterVariant: "valuePicker",
        sortVariant: "simple",
        resizable: true,
      },
    }),
  },
  id: {
    whenLoading: {
      id: "id",
      header: "Id",
      cell: () => <RaggedTextSkeleton />,
      meta: {
        resizable: true,
      },
    },
    otherwise: columnHelper.accessor("id", {
      id: "id",
      meta: {
        sortVariant: "simple",
        resizable: true,
      },
    }),
  },
};
